import React, { useEffect, useState } from "react"
import { message, Tabs } from "antd"
import type { TabsProps } from "antd"
import MessageInput from "./MessageInput/index"
import MessageInputPhone from "./MessageInput/indexPhone"
import QrInput from "./QrInput"
import "./index.scss"
import { useNavigate } from "react-router-dom"
import Util from "../../services/util"
import { ROLE_CODE } from "../../store/constants"
import { useDispatch } from "react-redux"
import { cross_env } from "../../services/serviceOp"
import { COMPANY_INFO } from "@/contents/companyInfo"
const util = Util.getInstance()

const Login: React.FC = () => {
  const dispatch = useDispatch()
  let [tabsKey, setTabsKey] = useState("message_tab")
  const nav = useNavigate()
  useEffect(() => {
    init()
    localStorage.removeItem("invoces")
    localStorage.removeItem("cacheInformation")
  }, []) // eslint-disable-line
  const init = async () => {
    const tokenObj = localStorage.getItem("operation_authorization") || "{}"
    if (JSON.parse(tokenObj).access_token) {
      localStorage.removeItem("operation_authorization")
    }
    const access_token = new URLSearchParams(window.location.search.substring(1)).get(
      "access_token"
    )
    if (access_token) {
      window.localStorage.setItem("operation_authorization", JSON.stringify({ access_token }))
      let redirect = new URLSearchParams(window.location.search.substring(1)).get("redirect")

      let result = (await getUserInfo()).data
      console.log(result, "result")
      result["access_token"] = access_token
      result["functionCode"] = result.authorities.join(",")
      result["userId"] = result?.id
      result["username"] = result?.name
      result["userName"] = result?.name
      localStorage.setItem("tokenMessage", JSON.stringify(result))
      localStorage.setItem("operation_authorization", JSON.stringify(result))
      dispatch({
        type: ROLE_CODE,
        payload: {
          codeList: result?.authorities,
          roleCodes: result?.roleCodes.split(","),
        },
      })
      if (redirect) {
        window.location.href = redirect
      } else {
        nav("/entity/transaction")
      }
      return
    }
    // const fail_login = new URLSearchParams(window.location.search.substring(1)).get("fail_login")
    // if (fail_login) {
    //   message.error("无登录权限，请联系管理员！")
    //   nav("/login")
    // }
  }
  const getUserInfo = async () => {
    return await util.httpGet(`${cross_env}/rscm/rscm-auth/oauth/token`)
  }
  // const items: TabsProps["items"] = [
  //   {
  //     key: "message_tab",
  //     label: "密码登录",
  //     children: <MessageInput />,
  //   },
  //   {
  //     key: "message_tabs",
  //     label: "",
  //     children: <MessageInputPhone />,
  //   },
  // ]
  return (
    <>
      <div className="login">
        <div
          className="login_right"
          style={{
            backgroundImage: `url(${COMPANY_INFO.company_login_left})`,
          }}
        ></div>
        <div className="sign-container">
          <div className="sign-container-login-right">
            <div className="sign-container-text">
              <img src={COMPANY_INFO.company_image} alt="title" />
              {/* <h2>{COMPANY_INFO.company_name}</h2> */}
            </div>
            <div className="sign-container-input">
              <MessageInput />
            </div>
          </div>
        </div>
        <div className="login_footer">{COMPANY_INFO.company_name}</div>
      </div>
    </>
  )
}
export default Login
