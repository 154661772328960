import FavXz from "../assets/favicon.png"
import FavGx from "../assets/gx_favicon.png"
import GXLogo from "../assets/gx_login_logo.png"
import XZLogo from "../assets/xz_login_logo.png"
import gxLeft from "../assets/gx_login_left.png"
import XZLeft from "../assets/xz_login_left.png"

// const company_info = {
//   xz: {
//     company_name: "运营中心",
//     company_image: XZLogo,
//     company_login_left: XZLeft,
//     fav: FavXz,
//   },

//   gx: {
//     company_name: "黑龙江省供销再生资源供应链服务平台",
//     company_image: GXLogo,
//     company_login_left: gxLeft,
//     fav: FavGx,
//   },
// }
// /**
//  * 修改浏览器页签图标
//  */
// const setFavIcon = (favicon: any) => {
//   const link = document.createElement("link")
//   link.rel = "icon"
//   link.href = favicon
//   document.head.append(link)
// }
// /**
//  * 修改title
//  */
// const setTitle = (title: string) => {
//   document.title = title
// }
// /**
//  * 生成域名配置地址
//  * @param config
//  * @returns
//  */
// const createWebnfig = (config: COMPANY) => {
//   const origin = window.location.origin.split("//")[1].toLocaleUpperCase() as "RSCM-EP.SAIKUL.COM"
//   if (URL[origin]) {
//     setFavIcon(config[URL[origin]]?.fav)
//     setTitle(config[URL[origin]]?.company_name)
//     return config[URL[origin]]
//   } else {
//     setFavIcon(config.xz?.fav)
//     setTitle(config.xz?.company_name)
//     return config.xz
//   }
// }

// export const COMPANY_INFO = createWebnfig(company_info)

// type COMPANY_TYPE = {
//   company_name: string
// }
// type COMPANY = {
//   xz: COMPANY_TYPE // 新之
//   gx: COMPANY_TYPE //  供销社
// }

type COMPANY_TYPE = {
  company_name: string
  company_image: string
  company_login_left: string
  fav: string
  mark: {
    label: string
    value: string
  }[]
}
type COMPANY = {
  xyrscm: COMPANY_TYPE // 建龙
  sinzetech: COMPANY_TYPE // 新之
  nbcoop: COMPANY_TYPE // 供销
  ly: COMPANY_TYPE // 绿易
}

type URL_TYPE = {
  "TEST-RSCM-OP.SAIKUL.COM": "sinzetech"
  "RSCM-OP.SAIKUL.COM": "sinzetech"
  "RSCM-OP.XYRSCM.COM": "xyrscm"
  "RSCM-OP.NBCOOP.COM.CN": "nbcoop"
  "WWW.NBCOOP.COM.CN": "nbcoop"
  "NBCOOP.COM.CN": "nbcoop"
  "WWW.LVYINET.COM": "ly"
  "LY.SAIKUL.COM": "ly"
  "WASTEFREE.SAIKUL.COM": "wufei"
  "HLJGX.SAIKUL.COM": "gx"
  "TEXT-HLJGX.SAIKUL.COM": "gx"
  "LOCALHOST:3000": "gx"
}
const ORIGINURL: URL_TYPE = {
  "TEST-RSCM-OP.SAIKUL.COM": "sinzetech",
  "RSCM-OP.SAIKUL.COM": "sinzetech",
  "RSCM-OP.XYRSCM.COM": "xyrscm",
  "RSCM-OP.NBCOOP.COM.CN": "nbcoop",
  "WWW.NBCOOP.COM.CN": "nbcoop",
  "NBCOOP.COM.CN": "nbcoop",
  "WWW.LVYINET.COM": "ly",
  "LY.SAIKUL.COM": "ly",
  "WASTEFREE.SAIKUL.COM": "wufei",
  "LOCALHOST:3000": "gx",
  "HLJGX.SAIKUL.COM": "gx",
  "TEXT-HLJGX.SAIKUL.COM": "gx",
}
const company_info = {
  xyrscm: {
    company_name: "建龙",
    mark: [{ label: "建龙", value: "default" }],
    company_login_left: "",
    fav: "",
    company_image: "",
  },
  sinzetech: {
    company_name: "新之",
    company_image: XZLogo,
    company_login_left: XZLeft,
    fav: FavXz,
    mark: [
      { label: "新之", value: "default" },
      { label: "绿易", value: "ly" },
    ],
  },
  nbcoop: {
    company_name: "供销",
    mark: [{ label: "供销", value: "default" }],
    company_login_left: "",
    fav: "",
    company_image: "",
  },
  ly: {
    company_name: "绿易",
    mark: [{ label: "绿易", value: "default" }],
    company_login_left: "",
    fav: "",
    company_image: "",
  },
  gx: {
    company_name: "黑龙江省供销再生资源供应链服务平台",
    company_image: GXLogo,
    company_login_left: gxLeft,
    fav: FavGx,
    mark: [{ label: "新之", value: "default" }],
  },
}

/**
 * 生成域名配置地址
 * @param config
 * @returns
 */
const createWebnfig = (config: COMPANY) => {
  const origin = window.location.origin.split("//")[1].toLocaleUpperCase() as "RSCM-EP.SAIKUL.COM"
  if (ORIGINURL[origin]) {
    return config[ORIGINURL[origin]]
  } else {
    return config.sinzetech
  }
}

export const COMPANY_INFO = createWebnfig(company_info)
