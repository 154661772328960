import React, { useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate, useRoutes } from "react-router-dom"
import { Login } from "../pages"
import lazyLoad from "./lazyLoad"
export const routeData: Array<any> = [
  {
    path: "/",
    element: <Login></Login>,
    meta: {
      key: "login",
    },
  },
  {
    path: "/login",
    element: lazyLoad(React.lazy(() => import("../pages/Login/index"))),
    meta: {
      key: "login",
    },
  },
  {
    path: "/video/surveillance",
    element: lazyLoad(React.lazy(() => import("../pages/Cockpit/VideoSurveillance/index"))),
    meta: {
      key: "videoSurveillance",
    },
  },
  {
    path: "*",
    element: lazyLoad(React.lazy(() => import("../pages/Error/index"))),
    meta: {
      key: "error",
    },
  },
  {
    path: "/",
    // redirect:'/entity'
    element: <Navigate to="/entity/transaction"></Navigate>,
    meta: {
      key: "redirect",
    },
  },
  // 数据统计
  {
    path: "/data",
    element: lazyLoad(React.lazy(() => import("@src/pages/DataCharts/index.tsx"))),
    children: [
      // 数据看版
      {
        path: "/data/chart",
        element: lazyLoad(React.lazy(() => import("@src/pages/DataCharts/Charts"))),
      },
      {
        path: "/data/large",
        element: lazyLoad(React.lazy(() => import("@src/pages/DataCharts/LargeScreen"))),
      },
    ],
  },
  // 店铺详情
  {
    path: "/agencyBusiness/storeDetail/:id",
    element: lazyLoad(React.lazy(() => import("../pages/BusinessAgent/StoreDetail/index"))),
  },
  // 实体交易
  {
    path: "/entity/transaction",
    element: lazyLoad(React.lazy(() => import("@src/pages/Entity"))),

    children: [
      // 采购订单
      {
        path: "/entity/transaction/procureOrder",
        element: lazyLoad(React.lazy(() => import("@/pages/Entity/orderManagement/ProcureOrder"))),
      },
      {
        path: "/entity/transaction/procureOrder/:id",
        element: lazyLoad(
          React.lazy(() => import("@src/pages/Entity/orderManagement/ProcureOrder/detail"))
        ),
      },

      // 平台品类
      {
        path: "/entity/transaction/platformClassification",
        element: lazyLoad(React.lazy(() => import("@/pages/Entity/platformClassification"))),
      },
      // 优惠券发放
      {
        path: "/entity/transaction/coupon",
        element: lazyLoad(React.lazy(() => import("@/pages/Entity/coupon/index"))),
      },
      // 交易方案
      {
        path: "/entity/transaction/tradingScheme",
        element: lazyLoad(React.lazy(() => import("@src/pages/Entity/TradingScheme/index"))),
      },
      // 交易方案新建
      {
        path: "/entity/transaction/TradingSchemeDetail/:id",
        element: lazyLoad(
          React.lazy(() => import("@src/pages/Entity/TradingScheme/NewTradingScheme/index"))
        ),
      },
      // 交易方案详情
      {
        path: "/entity/transaction/tradingScheme/:id",
        element: lazyLoad(
          React.lazy(() => import("@src/pages/Entity/TradingScheme/Details/index"))
        ),
      },
      // 合同模板管理
      {
        path: "/entity/transaction/contractTemplate",
        element: lazyLoad(React.lazy(() => import("@src/pages/Entity/contractTemplate/index"))),
      },
      // 合同模板管理新建
      {
        path: "/entity/transaction/contractTemplate/:type",
        element: lazyLoad(React.lazy(() => import("@src/pages/Entity/contractTemplate/create"))),
      },
      // 合同模板管理编辑
      {
        path: "/entity/transaction/contractTemplate/:type/:id",
        element: lazyLoad(React.lazy(() => import("@src/pages/Entity/contractTemplate/create"))),
      },
      // 合同模板管理详情
      {
        path: "/entity/transaction/contractTemplate/detail/:id",
        element: lazyLoad(React.lazy(() => import("@src/pages/Entity/contractTemplate/detail"))),
      },
    ],
  },
  {
    path: "/platformSetup",
    element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup"))),
    children: [
      {
        path: "/platformSetup/user",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/User/index"))),
        meta: {
          key: "system_user",
        },
      },
      {
        path: "/platformSetup/Check",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/Check"))),
        meta: {
          key: "system_check",
        },
      },
      {
        path: "/platformSetup/PublicAccount/TwoCode",
        element: lazyLoad(
          React.lazy(() => import("@src/pages/PlatformSetup/PublicAccount/TwoCode"))
        ),
        meta: {
          key: "system_twoCode",
        },
      },
      {
        path: "/platformSetup/user/:id",
        element: lazyLoad(
          React.lazy(() => import("@src/pages/PlatformSetup/User/UserModal/Detail"))
        ),
      },
      {
        path: "/platformSetup/apply",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/Apply"))),
        meta: {
          key: "system_apply",
        },
      },
      {
        path: "/platformSetup/apply/:id/:type",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/Apply/Detail"))),
      },
      {
        path: "/platformSetup/tissue",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/Tissue"))),
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/tissue/create/:applyNo",
        element: React.lazy(() => import("@src/pages/PlatformSetup/Tissue/create")),
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/tissue/info/:id",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/Tissue/info"))),
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/district",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/TaxDistrict"))),
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/district/create/:id",
        element: lazyLoad(
          React.lazy(() => import("@src/pages/PlatformSetup/TaxDistrict/NewTaxDistrict"))
        ),
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/district/:id",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/TaxDistrict/Details"))),
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/invoice",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/Invoice"))),
        meta: {
          key: "system_invoice",
        },
      },
      {
        path: "/platformSetup/utilOrder",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/UtilOrder"))),
        meta: {
          key: "system_utilOrder",
        },
      },
      {
        path: "/platformSetup/paySet",
        element: lazyLoad(React.lazy(() => import("@src/pages/PlatformSetup/PaySet"))),
        meta: {
          key: "system_paySet",
        },
      },
      {
        path: "/platformSetup/account/:id",
        element: React.lazy(
          () => import("../pages/PlatformSetup/Tissue/components/financial/openAccount/jzb/index")
        ),
      },
    ],
  },
  // 供应链金融
  {
    path: "/finance",
    element: lazyLoad(React.lazy(() => import("../pages/SupplyFinance/index"))),
    children: [
      // 个体户开办
      {
        path: "/finance/order",
        element: lazyLoad(React.lazy(() => import("../pages/SupplyFinance/financingOrder/index"))),
      },
      {
        path: "/finance/order/:id",
        element: lazyLoad(
          React.lazy(() => import("../pages/SupplyFinance/financingOrder/details/index"))
        ),
      },
      {
        path: "/finance/enterprise",
        element: lazyLoad(React.lazy(() => import("../pages/SupplyFinance/coreEnterprise/index"))),
      },
      {
        path: "/finance/enterprise/:id",
        element: lazyLoad(
          React.lazy(() => import("../pages/SupplyFinance/coreEnterprise/details/index"))
        ),
      },
      {
        path: "/finance/consent",
        element: lazyLoad(React.lazy(() => import("../pages/SupplyFinance/supplierAccess/index"))),
      },
      {
        path: "/finance/consent/:id",
        element: lazyLoad(
          React.lazy(() => import("../pages/SupplyFinance/supplierAccess/details/index"))
        ),
      },
      {
        path: "/finance/product",
        element: lazyLoad(
          React.lazy(() => import("../pages/SupplyFinance/financingProduct/index"))
        ),
      },
      {
        path: "/finance/product/:id",
        element: lazyLoad(
          React.lazy(() => import("../pages/SupplyFinance/financingProduct/details/index"))
        ),
      },
    ],
  },
  // 业务代办
  {
    path: "/agencyBusiness",
    element: lazyLoad(React.lazy(() => import("../pages/BusinessAgent/index"))),
    children: [
      // 个体户开办
      {
        path: "/agencyBusiness/setup",
        element: lazyLoad(React.lazy(() => import("../pages/BusinessAgent/selfEmployed/index"))),
      },
      {
        path: "/agencyBusiness/setup/newApplication/:id",
        element: lazyLoad(
          React.lazy(() => import("../pages/BusinessAgent/selfEmployed/newApplication"))
        ),
      },
      {
        path: "/agencyBusiness/setup/:id",
        element: lazyLoad(
          React.lazy(() => import("../pages/BusinessAgent/selfEmployed/details/index"))
        ),
      },
      {
        path: "/agencyBusiness/ModifyInformation/:id",
        element: lazyLoad(
          React.lazy(
            () => import("../pages/BusinessAgent/selfEmployed/details/components/modifyInformation")
          )
        ),
      },
      {
        path: "/agencyBusiness/outSetup",
        element: lazyLoad(
          React.lazy(() => import("../pages/BusinessAgent/outSelfEmployed/newLogout/index"))
        ),
      },
      {
        path: "/agencyBusiness/outSetup/:id",
        element: lazyLoad(
          React.lazy(() => import("../pages/BusinessAgent/outSelfEmployed/details/index"))
        ),
      },
      {
        path: "/agencyBusiness/outNewLogout",
        element: lazyLoad(
          React.lazy(() => import("../pages/BusinessAgent/outSelfEmployed/newLogout/index"))
        ),
      },
    ],
  },
  //开票系统
  {
    path: "/ticket",
    element: lazyLoad(React.lazy(() => import("../pages/ReceiptSystem/index"))),
    children: [
      {
        path: "/ticket/invoicingParty",
        element: lazyLoad(
          React.lazy(() => import("@src/pages/ReceiptSystem/pages/Ticket/InvoicingParty"))
        ),
      },
      {
        path: "/ticket/InvoicingTask",
        element: lazyLoad(React.lazy(() => import("../pages/ReceiptSystem/pages/InvoicingTask"))),
      },
      {
        path: "/ticket/InvoicingTask/info/:id",
        element: lazyLoad(
          React.lazy(() => import("../pages/ReceiptSystem/pages/InvoicingTask/InvoceInfo"))
        ),
      },
      {
        path: "/ticket/openTask",
        element: lazyLoad(
          React.lazy(() => import("@src/pages/ReceiptSystem/pages/openTask/openTask"))
        ),
      },
      {
        path: "/ticket/ledger",
        element: lazyLoad(React.lazy(() => import("@src/pages/ReceiptSystem/pages/ledger/ledger"))),
      },
    ],
  },
  // 存证平台
  {
    path: "/evidence",
    element: lazyLoad(React.lazy(() => import("../pages/Evidence"))),
    children: [
      // 模板列表
      {
        path: "/evidence/platform/template",
        element: lazyLoad(React.lazy(() => import("../pages/Evidence/pages/Template"))),
      },
      // 添加模版
      {
        path: "/evidence/platform/template/add",
        element: lazyLoad(
          React.lazy(() => import("../pages/Evidence/pages/Template/pages/AddTemplate"))
        ),
      },
      // 客户管理详情
      {
        path: "/evidence/platform/management/manage_info",
        element: lazyLoad(
          React.lazy(() => import("../pages/Evidence/pages/Management/pages/Info"))
        ),
        exact: true,
      },
      // 字段列表
      {
        path: "/evidence/platform/field",
        element: lazyLoad(React.lazy(() => import("../pages/Evidence/pages/Fields"))),
        meta: {
          key: "fields",
        },
      },
      // 字段模板创建和编辑
      {
        path: "/evidence/platform/field/add",
        element: lazyLoad(React.lazy(() => import("../pages/Evidence/pages/Fields/pages/Add"))),
      },
      // 存证场景关联字段信息
      {
        path: "/evidence/platform/field/list",
        element: lazyLoad(
          React.lazy(() => import("../pages/Evidence/pages/Fields/pages/FieldList"))
        ),
      },
      //   //客户关联存证
      {
        path: "/evidence/platform/add_evidence",
        element: lazyLoad(
          React.lazy(() => import("../pages/Evidence/pages/Management/pages/AddEvidence"))
        ),
      },
    ],
  },
  //物联管理
  {
    path: "/internet",
    element: lazyLoad(React.lazy(() => import("@src/pages/Internet/index"))),
    children: [
      {
        path: "/internet",
        element: lazyLoad(React.lazy(() => import("@src/pages/Internet/supervisory/index"))),
      },
    ],
  },
  //物联管理
  {
    path: "/logistics",
    element: lazyLoad(React.lazy(() => import("../pages/logistics/index"))),

    children: [
      {
        path: "/logistics/warehouse",
        element: lazyLoad(React.lazy(() => import("../pages/logistics/warehouse/index"))),
      },
      {
        path: "/logistics/trajectory",
        element: lazyLoad(React.lazy(() => import("../pages/logistics/Trajectory/index"))),
      },
    ],
  },
]

const Element = () => {
  const loca = JSON.parse(localStorage.getItem("operation_authorization") || "{}")?.access_token_op
  const navigate = useNavigate()
  const routerInfo = useLocation().pathname.split("/")[2]
  const [localRouteData, setRouteData] = useState(routeData)
  // 独立页面免登录
  useEffect(() => {
    if (!loca && routerInfo != "storeDetail") {
      navigate("/login")
    }
  }, [loca])
  useEffect(() => {
    if (!loca && routerInfo != "storeDetail") {
      const arr = [
        {
          path: "/login",
          element: <Login />,
          meta: {
            key: "login",
          },
        },
      ]
      setRouteData(arr)
    }
  }, [loca, routerInfo])
  return useRoutes(localRouteData)
}
export default Element
